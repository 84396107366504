import type { BoxProps } from '@chakra-ui/react'
import { Box, Button, Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import type { waitForTransactionReceipt } from '@wagmi/core'
import { getChainId } from '@wagmi/core'
import axios from 'axios'
import Big from 'big.js'
import Decimal from 'decimal.js'
import { isAddress } from 'ethers'
import type { GetServerSidePropsContext } from 'next'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiArrowRightLine, RiCheckboxCircleFill } from 'react-icons/ri'
import type { Address } from 'viem'
import { getTransaction } from 'viem/actions'
import type { Config } from 'wagmi'
import { cookieToInitialState, useAccount } from 'wagmi'
import { Alert } from 'components/Alert'
import { AmountInput } from 'components/AmountInput'
import { AmountLabel } from 'components/AmountLabel'
import { useIsTabletWidth } from 'components/Breakpoint'
import { BridgeApproveModal } from 'components/BridgeApproveModal'
import { BridgeQuarantinedModal } from 'components/BridgeQuarantinedModal'
import { BridgeSelector } from 'components/BridgeSelector/BridgeSelector'
import { BridgeSuccessModal } from 'components/BridgeSuccessModal'
import { ConnectButton } from 'components/ConnectButton'
import { CustomReceivingAddressInput } from 'components/CustomReceivingAddress'
import { ExternalLink } from 'components/ExternalLink'
import { InfoPopup } from 'components/InfoPopup'
import { Label } from 'components/Label'
import { Link } from 'components/Link'
import { MessageRelayerModal } from 'components/MessageRelayerModal'
import { ProgressBar } from 'components/ProgressBar'
import { SwitchNetworkModal } from 'components/SwitchNetworkModal'
import { Table } from 'components/Table'
import { TransactionButton } from 'components/TransactionButton'
import {
  DECIMALS,
  DEFAULT_VALUE,
  EXIT_TRANSACTION_TYPE,
  RECENT_TRANSACTIONS_LIMIT,
  RHINO_BRIDGE_LINK,
  TRANSACTION_TYPE_LABELS,
  TXN_STATUS_ICONS,
  TXN_STATUS_VARIANTS,
} from 'constants/common'
import { zircuit, l1, ETH, L1_LAYER_IDS, txTypeChainIdMap } from 'constants/network'
import { Routes } from 'constants/routes'
import { env } from 'env.client'
import type { RecentTransactionsListItem as TxItem } from 'generated/reactQueryClient'
import {
  RecentTransactionsListItemExitStatus as TxItemExitStatus,
  useGetRecentTransactions,
} from 'generated/reactQueryClient'
import { useBridge } from 'hooks/useBridge'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useGetGasFee, useGetL1WithdrawalGasFee } from 'hooks/useGasFee'
import { useHandleCurrentTransaction } from 'hooks/useHandleCurrentTransaction'
import { useHandleQuarantinedTransaction } from 'hooks/useHandleQuarantinedTransaction'
import { useThemeColors } from 'hooks/useThemeColors'
import { useToast } from 'hooks/useToast'
import { type UpdatedTransactionType, useUpdatedTransactions } from 'hooks/useUpdatedTransactions'
import { BaseLayout } from 'layouts/BaseLayout'
import { publicClientL1, publicClientL2 } from 'lib/viem'
import { wagmiConfig } from 'lib/wagmi'
import { useAssetContext } from 'providers/AssetProvider'
import { useWalletContext } from 'providers/WalletProvider'
import {
  calculateGasFees,
  doesTransactionHaveValidExitStatus,
  getAssetSymbol,
  getFormattedNumber,
  weiToEth,
} from 'utils/common'
import { extractToAddress } from 'utils/extractToAddress'
import { getBridgeButtonProps } from 'utils/getBridgeButtonProps'
import { getInitialFromNetwork } from 'utils/getInitialFromNetwork'
import { getInitialToNetwork } from 'utils/getInitialToNetwork'
import { getSelectedExternalBridge } from 'utils/getSelectedExternalBridge'
import { getTruncatedText } from 'utils/getTruncatedText'
import { serverSideTranslations } from 'utils/i18n'
import { captureError } from 'utils/sentry'

// Set the precision to a high value to avoid exponential notation
Big.PE = 1_000
Big.NE = -1_000

const MAX_INPUT_CHAR_LENGTH = 20
const WRAPPER_PX_OFFSET = { base: 4, md: 6 }
const WRAPPER_MX_OFFSET = { base: -4, md: -6 }

const COMMON_BUTTON_PROPS = {
  size: 'big',
  width: '100%',
  rightIcon: <RiArrowRightLine style={{ marginLeft: '16px', color: 'white' }} />,
}

const Wrapper = ({ children, ...props }: { children: React.ReactNode } & BoxProps) => (
  <Box pt={7} px={WRAPPER_PX_OFFSET} borderRadius={10} {...props}>
    {children}
  </Box>
)

const Checkbox = ({ label }: { label: string }) => {
  const COLORS = useThemeColors()

  return (
    <Flex alignItems="center" mt={3}>
      <RiCheckboxCircleFill color={COLORS.zircuitPrimary} size={24} />
      <Text ml={2} variant="text2medium">
        {label}
      </Text>
    </Flex>
  )
}

type BridgeProps = {
  initialFrom: { id: number; label: string }
  initialTo: { id: number; label: string }
}

const Bridge = ({ initialFrom, initialTo }: BridgeProps) => {
  const COLORS = useThemeColors()
  const toast = useToast()
  const { colorMode } = useColorMode()
  const { isTabletWidth } = useIsTabletWidth()
  const { t } = useTranslation(['common'])
  const { address, chain: activeChain, isConnected, status: accountStatus } = useAccount()
  const router = useRouter()
  const { selectedAsset, setSelectedAsset, ethBalance, selectedAssetBalance, assets } =
    useAssetContext()
  const { isConnectedAddressMultisigWallet, isConnectedMultisigWalletLoading } = useWalletContext()

  const [customReceiverState, dispatchCustomReceiverState] = React.useReducer(
    (
      state: { isEnabled: boolean; address: string; isValidAddress: boolean },
      action: { type: 'toggleReceiver' | 'setAddress'; payload?: string },
    ) => {
      switch (action.type) {
        case 'toggleReceiver': {
          return {
            ...state,
            address: state.isEnabled ? state.address : '',
            isEnabled: !state.isEnabled,
          }
        }
        case 'setAddress': {
          const newAddress = action.payload || ''

          return {
            ...state,
            address: newAddress,
            isValidAddress: isAddress(newAddress),
          }
        }
        default: {
          return state
        }
      }
    },
    { isEnabled: false, address: '', isValidAddress: false },
  )
  const [isSwitchNetworkModalOpen, setIsSwitchNetworkModalOpen] = React.useState<boolean>(false)
  const [isBridgeApproveModalOpen, setIsBridgeApproveModalOpen] = React.useState<boolean>(false)
  const [isBridgeQuarantinedModalOpen, setIsBridgeQuarantinedModalOpen] =
    React.useState<boolean>(false)
  const [internalBridgedTransactionData, setInternalBridgedTransactionData] =
    React.useState<TxItem | null>(null)
  const [bridgedTransaction, setBridgedTransaction] = React.useState<Awaited<
    ReturnType<typeof waitForTransactionReceipt>
  > | null>(null)
  const [pendingTransactionHash, setPendingTransactionHash] = React.useState<Address | null>(null)

  const [from, setFrom] = React.useState<{
    id: number
    label: string
  }>(initialFrom)
  const [to, setTo] = React.useState<{
    id: number
    label: string
  }>(initialTo)

  const [amount, setAmount] = React.useState<string>('')
  const debouncedAmount = useDebouncedValue(amount)
  const [selectedTransactionL2Hash, setSelectedTransactionL2Hash] = React.useState<string | null>(
    null,
  )
  const [isExecuted, setIsExecuted] = React.useState<boolean>(false)
  const [txInExecution, setTxInExecution] = React.useState<string | null>(null)

  const { data: recentTransactions, refetch } = useGetRecentTransactions(
    {
      limit: RECENT_TRANSACTIONS_LIMIT,
      txType: ['l1l2', 'l2l1'],
      ...(address && { address }),
    },
    { query: { enabled: isConnected } },
  )

  const { data: updatedRecentTransactions, isLoading: areUpdatedRecentTransactionsLoading } =
    useUpdatedTransactions({
      transactions: recentTransactions?.data ?? [],
    })

  const { data: ethValueInUsd, isLoading: isEthValueInUsdLoading } = useQuery({
    queryKey: ['getEthValueInUsd', debouncedAmount],
    enabled: !!debouncedAmount && selectedAsset.symbol === ETH.symbol,
    queryFn: async () => {
      const { data } = await axios.get<{ ethValueInUsd: string }>('api/ethValueInUsd', {
        params: { value: debouncedAmount },
      })

      return data.ethValueInUsd
    },
  })

  useHandleCurrentTransaction({
    transactions: recentTransactions,
    refetch,
    bridgedTransaction,
    setInternalBridgedTransactionData,
    from,
  })

  const selectedTransaction = React.useMemo(
    () =>
      recentTransactions?.data?.find(
        (tx) => tx.l2TxHash.toLowerCase() === selectedTransactionL2Hash?.toLowerCase(),
      ) ?? null,
    [recentTransactions?.data, selectedTransactionL2Hash],
  )

  const { currentQuarantinedTransactionData, isPendingTransactionQuarantined } =
    useHandleQuarantinedTransaction({
      pendingTransactionHash,
      setIsBridgeApproveModalOpen,
      setIsBridgeQuarantinedModalOpen,
    })

  const {
    data: gasFee = DEFAULT_VALUE,
    isFetching: isGasFeeLoading,
    refetch: refetchGasFee,
  } = useGetGasFee({
    amountToTransfer: new Big(debouncedAmount || '0').toString(), // Get normal number notation without rounding -> https://mikemcl.github.io/big.js/#toS
    chainId: from.id,
    isEnabled:
      // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
      !selectedAsset?.bridge &&
      isConnectedAddressMultisigWallet === false &&
      !isConnectedMultisigWalletLoading,
    ...(customReceiverState.address && {
      customReceivingAddress: customReceiverState.address as Address,
    }),
  })

  const { handleBridge } = useBridge({
    amount: new Big(amount || '0').toString(), // Get normal number notation without rounding -> https://mikemcl.github.io/big.js/#toS
    fromId: from.id,
    setBridgedTransaction,
    setPendingTransactionHash,
    setIsSwitchNetworkModalOpen,
    isPendingTransactionQuarantined,
    refetchGasFee,
    ...(customReceiverState.address && {
      customReceivingAddress: customReceiverState.address as Address,
    }),
  })

  const { data: l1WithdrawalGasFeeUsd = 0, isLoading: isL1WithdrawalGasFeeLoading } =
    useGetL1WithdrawalGasFee()

  const bridgeButtonProps = getBridgeButtonProps({
    amount,
    ethBalance,
    selectedAssetBalance,
    gasFee,
    asset: selectedAsset.symbol,
    t,
    from,
    customReceiverState,
    isConnectedAddressMultisigWallet: !!isConnectedAddressMultisigWallet,
  })

  const bridgeTitle = from.id === zircuit.id ? t('BridgeFromZircuit') : t('BridgeToZircuit')

  const handleActionButton = async (row: TxItem) => {
    try {
      const chainId = getChainId(wagmiConfig)

      if (chainId !== txTypeChainIdMap[row.txType]) {
        setIsSwitchNetworkModalOpen(true)
        return
      }

      const isExitTransactionType = row.txType === EXIT_TRANSACTION_TYPE

      if (row.txType === EXIT_TRANSACTION_TYPE) {
        setFrom({ id: zircuit.id, label: zircuit.name })
        setTo({ id: l1.id, label: l1.name })
      } else {
        setFrom({ id: l1.id, label: l1.name })
        setTo({ id: zircuit.id, label: zircuit.name })
      }

      setIsBridgeApproveModalOpen(true)

      setAmount(
        getFormattedNumber(
          weiToEth(row.transfers?.[0]?.wei ?? row.value, row.transfers?.[0]?.decimals ?? DECIMALS),
        ),
      )

      const assetToUse = assets.find(
        (asset) => asset.symbol === getAssetSymbol(row?.transfers?.[0]?.symbol),
      )

      setSelectedAsset(assetToUse ?? ETH)

      const transaction = await getTransaction(
        isExitTransactionType ? publicClientL2 : publicClientL1,
        {
          hash: isExitTransactionType ? (row.l2TxHash as Address) : (row.l1TxHash as Address),
        },
      )

      const toAddress = extractToAddress(transaction.input, transaction.from as Address)

      if (toAddress) {
        dispatchCustomReceiverState({ type: 'setAddress', payload: toAddress })
      }
    } catch (error) {
      // Reset the states
      setIsSwitchNetworkModalOpen(false)
      setIsBridgeApproveModalOpen(false)
      setFrom(initialFrom)
      setTo(initialTo)
      setAmount('')
      setSelectedAsset(ETH)
      dispatchCustomReceiverState({ type: 'setAddress', payload: undefined })

      if (error instanceof Error) {
        captureError(error)
      }

      toast({ status: 'danger', message: t('Error.SomethingWentWrong') })
    }
  }

  const selectedExternalBridge = React.useMemo(() => {
    // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
    if (selectedAsset?.bridge) {
      return getSelectedExternalBridge({
        // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
        bridge: selectedAsset.bridge,
        amount,
        isL1ToL2: from.id === l1.id,
        t,
      })
    }
    return null
  }, [selectedAsset, amount, from.id, t])

  // Check if the user is connected to the wrong network on page load and switch to the correct one if not
  React.useEffect(() => {
    if (accountStatus === 'connected') {
      const chainId = getChainId(wagmiConfig)

      if (chainId !== from.id) {
        setIsSwitchNetworkModalOpen(true)
      }
    }
  }, [accountStatus, from.id])

  const handleOnTransactionFinalized = React.useCallback(async () => {
    await refetch()
  }, [refetch])

  return (
    <>
      <Flex flexDir="column" alignItems="center">
        <Flex maxW={{ base: '100%', md: 895 }} w="100%" mb={4} gap={2} justifyContent="center">
          {/* Desktop */}
          <Wrapper
            justifyContent="center"
            justifySelf="center"
            w={335}
            bgImage={`/assets/portal_${colorMode}.png`}
            backgroundSize="cover"
            display={{ base: 'none', md: 'block' }}
          >
            <Text w={200} variant="heading4medium">
              {t('Page.Home.PortalTitle')}
            </Text>
            <Checkbox label={t('Page.Home.BulletproofBridge')} />
            <Checkbox label={t('Page.Home.PerformanceDriven')} />
            <Checkbox label={t('Page.Home.AccessTo100DApps')} />
            <Text mt={10} mb={2} variant="heading6regular">
              {t('ExternalBridge.RhinoBridgeDescription')}
            </Text>
            <Button
              onClick={() => window.open(RHINO_BRIDGE_LINK, '_blank', 'noopener,noreferrer')}
              width="100%"
            >
              <Text as="span" variant="text3medium" color="inherit">
                {t('ExternalBridge.RhinoBridge')}
              </Text>
            </Button>
          </Wrapper>
          <Wrapper maxW={600} w="100%" bg={COLORS.bgSecondary}>
            <Text variant="heading4medium">{bridgeTitle}</Text>
            <BridgeSelector
              mt={6}
              from={from}
              to={to}
              onFromChange={setFrom}
              onToChange={setTo}
              onIncorrectNetwork={() => {
                setIsSwitchNetworkModalOpen(true)
              }}
            />
            <AmountInput
              mt={{ base: 4, md: 6 }}
              mb={{ base: 2, md: 3 }}
              isGasFeeLoading={isGasFeeLoading}
              value={amount}
              onValueChange={(value) => {
                if (value.length <= MAX_INPUT_CHAR_LENGTH) {
                  setAmount(value)
                }
              }}
              onMaxPressed={(value) => {
                const asyncFn = async () => {
                  if (selectedAsset.symbol === ETH.symbol) {
                    const maxValue = new Decimal(selectedAssetBalance).sub(gasFee)

                    setAmount(maxValue.isNegative() ? value : maxValue.toString())
                  } else {
                    setAmount(value)
                  }

                  // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
                  if (!selectedAsset?.bridge) {
                    await refetchGasFee()
                  }
                }

                void asyncFn()
              }}
              onIncorrectNetwork={() => {
                setIsSwitchNetworkModalOpen(true)
              }}
            />
            <CustomReceivingAddressInput
              isEnabled={isConnectedAddressMultisigWallet ? true : customReceiverState.isEnabled}
              toggleIsEnabled={
                isConnectedAddressMultisigWallet
                  ? () => {}
                  : () => dispatchCustomReceiverState({ type: 'toggleReceiver' })
              }
              value={customReceiverState.address}
              onChange={(newAddress) =>
                dispatchCustomReceiverState({ type: 'setAddress', payload: newAddress })
              }
              isValid={customReceiverState.isValidAddress}
              isCheckboxDisabled={!!isConnectedAddressMultisigWallet}
            />
            <Box
              mt={8}
              mx={WRAPPER_MX_OFFSET}
              px={WRAPPER_PX_OFFSET}
              border="1px solid"
              borderColor={COLORS.grey06}
              bgColor={COLORS.bgPrimary}
              borderRadius={10}
            >
              <AmountLabel
                mt={8}
                label={t('Page.Home.YouAreMoving')}
                value={`${amount || 0} ${selectedAsset.symbol}${selectedAsset.symbol === ETH.symbol ? ` ($${ethValueInUsd || 0})` : ''}`}
                isValueLoading={isEthValueInUsdLoading}
              />
              {/* @ts-expect-error - Erc20Token | ExternalErc20Token Union type */}
              {isConnectedAddressMultisigWallet && !selectedAsset?.bridge && (
                <Flex align="center" gap={1} mt={3}>
                  <AmountLabel
                    w="100%"
                    label={t('Page.Home.EstimatedGasFees')}
                    value={t('Page.Home.Unavailable')}
                  />
                  <InfoPopup title={t('Page.Home.GasEstimateUnavailable')}>
                    <Text>{t('Page.Home.GasEstimateUnavailableTooltip')}</Text>
                  </InfoPopup>
                </Flex>
              )}
              {/* @ts-expect-error - Erc20Token | ExternalErc20Token Union type */}
              {!isConnectedAddressMultisigWallet && !selectedAsset?.bridge && (
                <AmountLabel
                  mt={3}
                  label={t('Page.Home.EstimatedGasFees')}
                  value={`${gasFee || 0} ${ETH.symbol}`}
                  isValueLoading={isGasFeeLoading || isConnectedMultisigWalletLoading}
                />
              )}
              <Box mt={4} w="100%" h="1px" bgColor={COLORS.grey06} />
              <AmountLabel
                mt={6}
                labelColor={COLORS.dark01}
                textVariant="title2medium"
                label={t('Page.Home.TotalAmount')}
                value={`${
                  selectedAsset.symbol === ETH.symbol
                    ? String(new Decimal(amount || '0').plus(new Decimal(gasFee || '0')))
                    : amount || 0
                } ${selectedAsset.symbol}`}
              />
              <Box mb={6} mt={9}>
                {activeChain?.id !== from.id && isConnected ? (
                  <Button
                    {...COMMON_BUTTON_PROPS}
                    onClick={() => {
                      setIsSwitchNetworkModalOpen(true)
                    }}
                  >
                    {t('Page.Home.SwitchNetwork')}
                  </Button>
                ) : (
                  <>
                    {isConnected ? (
                      <>
                        {selectedExternalBridge && Big(amount || '0').gt(0) ? (
                          <>
                            <Text
                              variant="caption2regular"
                              textAlign="center"
                              color={COLORS.grey02}
                              mb={3}
                            >
                              {t('ExternalBridge.Warning', { asset: selectedAsset.name })}
                            </Text>
                            <Button
                              {...COMMON_BUTTON_PROPS}
                              as={Link}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={selectedExternalBridge.url}
                              color="white !important" // *: Fixes the styles override from the as prop
                            >
                              {t('ExternalBridge.BridgeOn')}
                              <Image
                                // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
                                src={`${env.NEXT_PUBLIC_ERC20_CDN_URL}/${env.NEXT_PUBLIC_NETWORK}/external/bridges/${selectedAsset.bridge}.png`}
                                // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
                                alt={selectedAsset.bridge}
                                borderRadius="full"
                                w={5}
                                h={5}
                                mx={2}
                              />
                              {selectedExternalBridge.bridgeMessage}
                            </Button>
                          </>
                        ) : (
                          <Button
                            {...COMMON_BUTTON_PROPS}
                            isDisabled={bridgeButtonProps.isDisabled || !amount || isGasFeeLoading}
                            onClick={() => {
                              if (activeChain?.id === from.id) {
                                setIsBridgeApproveModalOpen(true)
                              } else {
                                setIsSwitchNetworkModalOpen(true)
                              }
                            }}
                          >
                            {bridgeButtonProps.label}
                          </Button>
                        )}
                        {selectedExternalBridge &&
                          // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
                          selectedAsset.bridge === 'everclear' &&
                          selectedAsset.symbol === 'pufETH' && (
                            <Alert mt={3} variant="warning">
                              {t('ExternalBridge.EverclearPufEthBridgeWarning')}
                            </Alert>
                          )}
                      </>
                    ) : (
                      <ConnectButton {...COMMON_BUTTON_PROPS} />
                    )}
                  </>
                )}
                {isTabletWidth && (
                  <Flex width="100%" justify="center" mt={4}>
                    <ExternalLink
                      _hover={{ textDecor: 'underline', color: COLORS.zircuitPrimary }}
                      href={RHINO_BRIDGE_LINK}
                      label={t('ExternalBridge.RhinoBridgeDescription')}
                      color={COLORS.zircuitPrimary}
                    />
                  </Flex>
                )}
              </Box>
            </Box>
          </Wrapper>
        </Flex>
        {isConnected ? (
          <Table
            maxW={{ base: 600, md: 895 }}
            w="100%"
            data={updatedRecentTransactions ?? []}
            hasPagination={false}
            title={t('Page.Home.RecentTransactions')}
            titleTooltip={t('Page.Home.RecentTransactionsTooltip')}
            isLoading={areUpdatedRecentTransactionsLoading}
            loaderHeight="24.75rem"
            rightHeaderContent={
              <Text
                onClick={async () => {
                  await router.push(Routes.transactions())
                }}
                color={COLORS.zircuitPrimary}
                variant="text3medium"
                _hover={{ cursor: 'pointer' }}
              >
                {t('Page.Home.SeeAllTransactions')}
              </Text>
            }
            {...(isTabletWidth
              ? {
                  renderMobile: (row: UpdatedTransactionType) => {
                    const hasValidExitStatus = doesTransactionHaveValidExitStatus(row.exitStatus)

                    return (
                      <Flex
                        key={row.l2TxHash}
                        p={4}
                        flexDir="column"
                        border="1px solid"
                        borderColor={COLORS.grey06}
                        borderRadius={10}
                        gap={4}
                        display={{ base: 'flex', md: 'none' }}
                        _notLast={{ mb: 3 }}
                      >
                        <Flex alignItems="center" justifyContent="space-between">
                          <Label
                            variant={TXN_STATUS_VARIANTS[row.status]}
                            icon={TXN_STATUS_ICONS[row.status]}
                          >
                            {row.status}
                          </Label>
                        </Flex>
                        <ProgressBar
                          status={
                            row.txType === EXIT_TRANSACTION_TYPE ? row.exitStatus : row.status
                          }
                        />
                        <Flex justifyContent="space-between">
                          <Box>
                            <Text mb={1.5} variant="caption2regular" color={COLORS.grey03}>
                              {t('TransactionsTable.Type')}
                            </Text>
                            <Text variant="text2medium">
                              {
                                TRANSACTION_TYPE_LABELS[
                                  row?.txType?.toLowerCase() as keyof typeof TRANSACTION_TYPE_LABELS
                                ]
                              }
                            </Text>
                            <ExternalLink
                              variant="secondary"
                              {...(row.txType === EXIT_TRANSACTION_TYPE &&
                              ![
                                TxItemExitStatus.waiting,
                                TxItemExitStatus.ready_to_prove,
                                TxItemExitStatus.in_challenge_period,
                              ].some((status) => row.exitStatus === status)
                                ? {
                                    href: `${env.NEXT_PUBLIC_L1_URL}/tx/${row.l1TxHash}`,
                                    label: getTruncatedText(row?.l1TxHash ?? ''),
                                  }
                                : {
                                    href: `${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/tx/${row.l2TxHash}`,
                                    label: getTruncatedText(row?.l2TxHash ?? ''),
                                  })}
                            />
                          </Box>
                          <Box>
                            <Text mb={1.5} variant="caption2regular" color={COLORS.grey03}>
                              {t('TransactionsTable.Amount')}
                            </Text>
                            <Text variant="text2medium">
                              {getFormattedNumber(
                                weiToEth(
                                  row.transfers?.[0]?.wei ?? row.value,
                                  row.transfers?.[0]?.decimals ?? DECIMALS,
                                ),
                              )}{' '}
                              {getAssetSymbol(row?.transfers?.[0]?.symbol)}
                            </Text>
                          </Box>
                        </Flex>
                        <TransactionButton
                          transaction={row}
                          txInExecution={txInExecution}
                          hasValidExitStatus={hasValidExitStatus}
                          onClick={() => {
                            if (hasValidExitStatus) {
                              setIsExecuted(false)
                              if (row.l2TxHash) {
                                setSelectedTransactionL2Hash(row.l2TxHash)
                              }
                            } else {
                              void handleActionButton(row)
                            }
                          }}
                          onTransactionFinalized={handleOnTransactionFinalized}
                        />
                      </Flex>
                    )
                  },
                }
              : {
                  renderTableRow: (row: UpdatedTransactionType) => {
                    const hasValidExitStatus = doesTransactionHaveValidExitStatus(row.exitStatus)

                    return (
                      <Table.Row key={row.l2TxHash}>
                        <Table.RowCell>
                          <Label
                            variant={TXN_STATUS_VARIANTS[row.status]}
                            icon={TXN_STATUS_ICONS[row.status]}
                          >
                            {row.status}
                          </Label>
                        </Table.RowCell>
                        <Table.RowCell>
                          <Box isTruncated>
                            <Text variant="title2medium" mb={1.5}>
                              {
                                TRANSACTION_TYPE_LABELS[
                                  row?.txType?.toLowerCase() as keyof typeof TRANSACTION_TYPE_LABELS
                                ]
                              }
                            </Text>
                            <ExternalLink
                              variant="secondary"
                              {...(row.txType === EXIT_TRANSACTION_TYPE &&
                              ![
                                TxItemExitStatus.waiting,
                                TxItemExitStatus.ready_to_prove,
                                TxItemExitStatus.in_challenge_period,
                              ].some((status) => row.exitStatus === status)
                                ? {
                                    href: `${env.NEXT_PUBLIC_L1_URL}/tx/${row.l1TxHash}`,
                                    label: getTruncatedText(row?.l1TxHash ?? ''),
                                  }
                                : {
                                    href: `${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/tx/${row.l2TxHash}`,
                                    label: getTruncatedText(row?.l2TxHash ?? ''),
                                  })}
                            />
                          </Box>
                        </Table.RowCell>
                        <Table.RowCell>
                          <Text variant="text2medium">
                            {getFormattedNumber(
                              weiToEth(
                                row.transfers?.[0]?.wei ?? row.value,
                                row.transfers?.[0]?.decimals ?? DECIMALS,
                              ),
                            )}{' '}
                            {getAssetSymbol(row?.transfers?.[0]?.symbol)}
                          </Text>
                        </Table.RowCell>
                        <Table.RowCell>
                          <ProgressBar
                            status={
                              row.txType === EXIT_TRANSACTION_TYPE ? row.exitStatus : row.status
                            }
                          />
                        </Table.RowCell>
                        <Table.RowCell>
                          <TransactionButton
                            transaction={row}
                            txInExecution={txInExecution}
                            hasValidExitStatus={hasValidExitStatus}
                            onClick={() => {
                              if (hasValidExitStatus) {
                                setIsExecuted(false)
                                if (row.l2TxHash) {
                                  setSelectedTransactionL2Hash(row.l2TxHash)
                                }
                              } else {
                                void handleActionButton(row)
                              }
                            }}
                            onTransactionFinalized={handleOnTransactionFinalized}
                          />
                        </Table.RowCell>
                      </Table.Row>
                    )
                  },
                })}
          />
        ) : (
          <Flex
            border="1px solid"
            borderColor={COLORS.grey06}
            maxW={{ base: 500, md: 850 }}
            w="100%"
            mt={10}
            pt={0.5}
            pb={3.5}
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            borderRadius={10}
          >
            <Image src={`/assets/not_connected_${colorMode}.svg`} />
            <Text variant={{ base: 'title1medium', md: 'heading4medium' }} mb={2}>
              {t('Page.Home.ConnectWalletToAccessRecentTransactions')}
            </Text>
            <Text variant={{ base: 'text3regular', md: 'text2regular' }} color={COLORS.grey02}>
              {t('WeNeedYourWalletAddressToCollectData')}
            </Text>
          </Flex>
        )}
      </Flex>
      <SwitchNetworkModal
        isOpen={isSwitchNetworkModalOpen}
        targetNetwork={activeChain?.id === from.id ? to : from}
        onClose={() => {
          setIsSwitchNetworkModalOpen(false)
        }}
        title={t('SwitchToNetwork', { networkName: from.label })}
      />
      <BridgeApproveModal
        estimatedL1GasFee={l1WithdrawalGasFeeUsd}
        isOpen={isBridgeApproveModalOpen}
        amount={amount}
        from={from}
        to={to}
        gasFee={gasFee}
        isGasFeeLoading={isGasFeeLoading || isL1WithdrawalGasFeeLoading}
        isConnectedAddressMultisigWallet={!!isConnectedAddressMultisigWallet}
        isConnectedMultisigWalletLoading={isConnectedMultisigWalletLoading}
        title={bridgeTitle}
        isPendingTransactionQuarantined={isPendingTransactionQuarantined}
        onApprove={async () => {
          await handleBridge()
          dispatchCustomReceiverState({ type: 'setAddress', payload: '' })
          if (customReceiverState.isEnabled) {
            dispatchCustomReceiverState({ type: 'toggleReceiver' })
          }
          setIsBridgeApproveModalOpen(false)
        }}
        onClose={() => {
          setIsBridgeApproveModalOpen(false)
        }}
        onIncorrectNetwork={() => {
          setIsSwitchNetworkModalOpen(true)
        }}
        {...(customReceiverState.address && {
          customReceivingAddress: customReceiverState.address as Address,
        })}
      />
      <BridgeSuccessModal
        isOpen={Boolean(bridgedTransaction)}
        onClick={async () => {
          await router.push(Routes.transactions())
        }}
        gasFee={calculateGasFees({
          gasUsed: bridgedTransaction?.gasUsed ? Number(bridgedTransaction.gasUsed) : 0,
          gasPrice: bridgedTransaction?.effectiveGasPrice
            ? Number(bridgedTransaction.effectiveGasPrice)
            : 0,
        })}
        onClose={() => {
          void refetch()
          setAmount('')
          setBridgedTransaction(null)
        }}
        received={amount}
        txType={L1_LAYER_IDS.includes(from.id) ? 'deposit' : 'withdrawal'}
        bridgedTransactionData={internalBridgedTransactionData}
      />
      <BridgeQuarantinedModal
        isOpen={isBridgeQuarantinedModalOpen}
        onClose={() => {
          setIsBridgeQuarantinedModalOpen(false)
          setPendingTransactionHash(null)
        }}
        reason={currentQuarantinedTransactionData?.quarantinedReason ?? ''}
      />
      <MessageRelayerModal
        data={selectedTransaction}
        isConnected={isConnected}
        isExecuted={isExecuted}
        onClearData={async () => {
          await refetch()
          setSelectedTransactionL2Hash(null)
        }}
        onExecutionChange={(tx) => {
          setTxInExecution(tx)
        }}
        onIncorrectNetwork={() => {
          setIsSwitchNetworkModalOpen(true)
        }}
        onSuccessBridge={async () => {
          await refetch()
          setIsExecuted(true)
        }}
        txInExecution={txInExecution}
      />
    </>
  )
}

Bridge.getLayout = (page: React.ReactNode) => <BaseLayout>{page}</BaseLayout>

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const initialState = cookieToInitialState(wagmiConfig as Config, context.req.headers.cookie)
  const userChainId = initialState?.chainId

  return {
    props: {
      ...(await serverSideTranslations(context, ['common'])),
      initialFrom: getInitialFromNetwork(userChainId),
      initialTo: getInitialToNetwork(userChainId),
    },
  }
}

export default Bridge

import type { FormControlProps } from '@chakra-ui/react'
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Skeleton,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useAccount } from 'wagmi'
import { AssetPicker } from 'components/AssetPicker'
import { FormFieldWrapper } from 'components/FormFieldWrapper'
import { COLOR_MODE } from 'constants/common'
import { useThemeColors } from 'hooks/useThemeColors'
import { useAssetContext } from 'providers/AssetProvider'
import { getImageSrc } from 'utils/getImageSrc'

type AmountInputProps = {
  value: string
  onValueChange: (value: string) => void
  onMaxPressed: (value: string) => void
  isGasFeeLoading: boolean
  onIncorrectNetwork: () => void
  isDisabled?: boolean
} & FormControlProps

export const AmountInput = ({
  value,
  onValueChange,
  onMaxPressed,
  isGasFeeLoading,
  onIncorrectNetwork,
  isDisabled = false,
  ...props
}: AmountInputProps) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const [isFocused, setIsFocused] = React.useState<boolean>(false)
  const { t } = useTranslation(['common'])
  const {
    areAssetsWithBalanceLoading,
    selectedAsset,
    setSelectedAsset,
    selectedAssetBalance,
    assetsWithBalance,
    assets,
  } = useAssetContext()
  const { address, isConnected } = useAccount()

  return (
    <FormFieldWrapper
      label={t('AmountInput.Amount')}
      {...(isConnected && {
        rightContent:
          selectedAsset && selectedAssetBalance ? (
            <Flex gap={1}>
              <Text variant="text3medium">
                {selectedAssetBalance} {selectedAsset.symbol}
              </Text>
              <Button
                py={0}
                px={2}
                isDisabled={isGasFeeLoading}
                variant="text"
                color={COLORS.zircuitPrimary}
                onClick={() => {
                  onMaxPressed(selectedAssetBalance)
                }}
              >
                {t('AmountInput.Max')}
              </Button>
            </Flex>
          ) : (
            <Skeleton w="52" />
          ),
      })}
      isDisabled={isDisabled}
      {...props}
    >
      <InputGroup
        // As we use chakra and styling of a group "active" component is not possible, there is a workaround with focus/blur events.
        {...(isFocused && { borderColor: COLORS.zircuitLight, _hover: { borderColor: 'none' } })}
        {...(isDisabled && { borderColor: COLORS.grey03, _hover: { borderColor: 'none' } })}
        variant="grouped"
        size={{ base: 'base', md: 'big' }}
      >
        <Input
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={() => {
            setIsFocused(false)
          }}
          placeholder="0.00000001"
          type="number"
          value={value}
          onChange={(event) => {
            // Prevent negative numbers.
            onValueChange(event.target.value.replace('-', ''))
          }}
        />
        <InputRightAddon bg={colorMode === COLOR_MODE.LIGHT ? 'white' : 'grey10'}>
          <AssetPicker
            modalTitle={t('AssetPicker.SelectAsset')}
            pickerStyle={{
              border: 'none',
              px: 1,
            }}
            iconColor={COLORS.grey04}
            textVariant="heading4medium"
            isLoading={areAssetsWithBalanceLoading}
            value={{ id: selectedAsset.id, label: selectedAsset.symbol }}
            onValueChange={(asset) => {
              setSelectedAsset(asset)
            }}
            options={address && isConnected ? assetsWithBalance : assets}
            imageSrc={getImageSrc({
              symbol: selectedAsset.symbol,
              contract_address_l1:
                'contract_address_l1' in selectedAsset
                  ? selectedAsset.contract_address_l1
                  : undefined,
              // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
              isManual: selectedAsset.isManual,
              // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
              bridge: selectedAsset.bridge,
            })}
            onIncorrectNetwork={onIncorrectNetwork}
          />
        </InputRightAddon>
      </InputGroup>
    </FormFieldWrapper>
  )
}
